






























import { Vue, Component } from 'vue-property-decorator';
import PaymentForm from './PaymentForm.vue';
import state from '../../State';
import {
  paymentViewToPayment,
  getDefaultPaymentView,
  clonePayment
} from './Payments.vue';
import { FrontendPayment } from '../../../../types';

@Component({
  name: 'AddPayment',
  components: { PaymentForm }
})
export default class AddPayment extends Vue {
  dialog = false;
  invalid = false;
  payment: FrontendPayment = getDefaultPaymentView();
  form: any = null; // eslint-ignore-line

  onForm(form: any) {
    this.form = form;
  }

  save() {
    const valid = this.form.validate();
    if (!valid) {
      this.invalid = true;
      setTimeout(() => {
        this.invalid = false;
      }, 3000);
      return;
    }
    const payment = paymentViewToPayment(this.payment);
    void fetch(state.get('request.url.payments'), {
      ...state.get('request.params.api.post'),
      body: JSON.stringify(payment)
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) {
          this.dialog = false;
          this.payment = getDefaultPaymentView();
          this.$emit('add', clonePayment(data.payment));
        } else {
          this.$emit('message', {
            message: data.message,
            icon: 'las la-bug',
            color: 'red'
          });
        }
      });
  }

  close() {
    this.form.reset();
    this.dialog = false;
  }
}
