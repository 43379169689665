var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-4"},[_vm._v("las la-wallet")]),_vm._v("Payments "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"las la-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.getPayments}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("las la-sync")]),_vm._v("Refresh ")],1),_c('add-payment',{on:{"add":_vm.onAdd,"message":_vm.onMessage}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.model.payments,"search":_vm.search,"footer-props":{
      showFirstLastPage: true
    }},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm.dayjs(item.createdAt).format('YYYY-MM-DD')))]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("las la-bars")])],1)]}}],null,true)},[_c('v-list',[_c('update-payment',{attrs:{"payment":item},on:{"update":_vm.onUpdate,"message":_vm.onMessage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("las la-edit")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1)]}}],null,true)}),_c('v-list-item',{on:{"click":function($event){return _vm.removePayment(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("las la-trash")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}])}),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar.color,"rounded":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.snackbar.icon))]),_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }