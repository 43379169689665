




























import { Vue, Component, Prop } from 'vue-property-decorator';
import PaymentForm from './PaymentForm.vue';
import state from '../../State';
import { paymentViewToPayment, clonePayment } from './Payments.vue';
import { FrontendPayment } from '../../../../types';

@Component({
  name: 'UpdatePayment',
  components: { PaymentForm }
})
export default class UpdateLicense extends Vue {
  dialog = false;
  invalid = false;
  form: any = null; // eslint-ignore-line
  editedPayment!: FrontendPayment;

  @Prop()
  private payment!: FrontendPayment;

  created() {
    this.$watch(
      'payment',
      (payment: FrontendPayment) => {
        this.editedPayment = payment;
      },
      { immediate: true }
    );
  }

  onForm(form: any) {
    this.form = form;
  }

  save() {
    const valid = this.form.validate();
    if (!valid) {
      this.invalid = true;
      setTimeout(() => {
        this.invalid = false;
      }, 3000);
      return;
    }
    const payment = paymentViewToPayment(this.editedPayment, false);
    void fetch(state.get('request.url.payments'), {
      ...state.get('request.params.api.put'),
      body: JSON.stringify(payment)
    })
      .then(res => res.json())
      .then(data => {
        this.dialog = false;
        if (data.ok) {
          this.$emit('update', clonePayment(data.payment));
        } else {
          this.$emit('message', {
            message: data.message,
            icon: 'las la-bug',
            color: 'red'
          });
        }
      });
  }

  close() {
    this.dialog = false;
  }
}
