























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { licenseTypes } from '../Licenses/LicenseForm.vue';
import { getDefaultInstallment } from './Payments.vue';
import { Payment } from '../../../../types';

@Component({
  name: 'PaymentForm'
})
export default class PaymentForm extends Vue {
  private licenseTypes = licenseTypes;
  @Prop()
  private model!: Payment;
  private console = console;

  rules = {
    single: [(value: string) => !!value || 'This field is required.'],
    number: [
      (value: number) => {
        value = Number(value);
        if (isNaN(value)) return 'This field is required.';
        return true;
      }
    ],
    multi: [(value: string[]) => !!value.length || 'This field is required']
  };

  created() {
    this.$watch('model.installmentsCount', (installmentsCount: number) => {
      const len = this.model.installments.length;
      const toAdd = installmentsCount - len;
      if (toAdd > 0) {
        for (let i = 0; i < toAdd; i++) {
          this.model.installments.push(getDefaultInstallment());
        }
      }
      this.model.installments = this.model.installments.filter(
        (inst, index) => index < installmentsCount
      );
    });
  }

  mounted() {
    this.$emit('form', this.$refs.form);
  }
}
