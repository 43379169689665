












































































import { Component, Vue } from 'vue-property-decorator';
import state from '../../State';
import dayjs from 'dayjs';
import AddPayment from './AddPayment.vue';
import UpdatePayment from './UpdatePayment.vue';
import {
  FrontendPayment,
  Payment,
  Installment,
  SnackbarMessage
} from '../../../../types';

export function paymentToPaymentView(payment: Payment): FrontendPayment {
  const result = { ...payment } as FrontendPayment;
  result.totalPrice = result.installments
    .map((inst: Installment) => inst.total)
    .reduce((prev, curr) => prev + curr, 0);
  result.totalPaid = result.installments
    .map((inst: Installment) => inst.paid)
    .reduce((prev, current) => {
      return prev + current;
    }, 0);
  result.totalToPay = result.totalPrice - result.totalPaid;
  result.paidInstallmentsCount = result.installments.filter(
    (inst: Installment) => inst.paid === inst.total
  ).length;
  result.toPayInstallmentsCount =
    result.installmentsCount - result.paidInstallmentsCount;
  return result;
}

export function fixPaymentNumbers(payment: Payment): Payment {
  payment.installmentsCount = Number(payment.installmentsCount);
  payment.quantity = Number(payment.quantity);
  payment.schemeVersion = Number(payment.schemeVersion);
  payment.installments = payment.installments.map((inst: Installment) => {
    inst.paid = Number(inst.paid);
    inst.total = Number(inst.total);
    return inst;
  });
  return payment;
}

export function paymentViewToPayment(
  payment: FrontendPayment,
  deleteId = true
): Payment {
  const body = { ...payment } as FrontendPayment;
  if (typeof body.paidInstallmentsCount !== 'undefined')
    delete body.paidInstallmentsCount;
  if (typeof body.toPayInstallmentsCount !== 'undefined')
    delete body.toPayInstallmentsCount;
  if (typeof body.totalPaid !== 'undefined') delete body.totalPaid;
  if (typeof body.totalToPay !== 'undefined') delete body.totalToPay;
  if (typeof body.totalPrice !== 'undefined') delete body.totalPrice;
  if (typeof body.modal !== 'undefined') delete body.modal;
  if (deleteId && typeof body._id !== 'undefined') delete body._id;
  if (deleteId && typeof body.createdAt !== 'undefined') delete body.createdAt;
  return fixPaymentNumbers(body);
}

export function getDefaultInstallment(): Installment {
  return {
    paymentDate: dayjs().format('YYYY-MM-DD'),
    paid: 0,
    total: 0,
    currency: 'USD'
  };
}

export function getDefaultPaymentView(): FrontendPayment {
  return {
    _id: '',
    createdAt: '',
    company: '',
    email: '',
    installments: [getDefaultInstallment()],
    installmentsCount: 1,
    schemeVersion: 1,
    quantity: 1,
    licenseType: { text: 'Regular', value: 'regular', version: 1.1 },
    modal: false,
    paidInstallmentsCount: 0,
    toPayInstallmentsCount: 0,
    totalPaid: 0,
    totalToPay: 0,
    totalPrice: 0
  };
}

export function clonePayment(
  payment: Payment | FrontendPayment
): Payment | FrontendPayment {
  return {
    ...payment,
    licenseType: { ...payment.licenseType },
    installments: payment.installments.map((inst: Installment) => {
      return { ...inst };
    })
  };
}

@Component({
  name: 'Payments',
  components: { AddPayment, UpdatePayment }
})
export default class Payments extends Vue {
  private dayjs = dayjs;
  private search = '';
  headers = [
    { align: 'left', text: 'Created at', value: 'createdAt' },
    { align: 'left', text: 'Company', value: 'company' },
    { align: 'left', text: 'Email', value: 'email' },
    { align: 'left', text: 'Price', value: 'totalPrice' },
    { align: 'left', text: 'Paid', value: 'totalPaid' },
    { align: 'left', text: 'To pay', value: 'totalToPay' },
    { align: 'left', text: 'Inst. count', value: 'installmentsCount' },
    { align: 'left', text: 'Paid inst. count', value: 'paidInstallmentsCount' },
    {
      align: 'left',
      text: 'To pay inst. count',
      value: 'toPayInstallmentsCount'
    },
    { align: 'left', text: 'Actions', value: 'actions' }
  ];
  model: { payments: FrontendPayment[] } = { payments: [] };
  snackbar = {
    visible: false,
    color: 'wraning',
    message: '',
    icon: ''
  };

  created() {
    this.getPayments();
  }

  getPayments() {
    void fetch(state.get('request.url.payments'), {
      ...state.get('request.params.api.get')
    })
      .then(res => res.json())
      .then(data => {
        this.model.payments = data.payments.map(paymentToPaymentView);
      });
  }

  onMessage(message: SnackbarMessage) {
    this.snackbar.message = message.message;
    this.snackbar.color = message.color || 'success';
    this.snackbar.icon = message.icon || 'las la-check-square';
    this.snackbar.visible = true;
  }

  onAdd(payment: Payment) {
    this.model.payments.push(paymentToPaymentView(payment));
    this.snackbar.message = 'Payment has been created.';
    this.snackbar.color = 'success';
    this.snackbar.icon = 'las la-check-square';
    this.snackbar.visible = true;
  }

  onUpdate(payment: Payment) {
    this.model.payments = this.model.payments.map(pay => {
      if (pay._id === payment._id) return paymentToPaymentView(payment);
      return pay;
    });
    this.snackbar.message = 'Payment has been updated.';
    this.snackbar.color = 'success';
    this.snackbar.icon = 'las la-check-square';
    this.snackbar.visible = true;
  }

  removePayment(payment: FrontendPayment) {
    fetch(state.get('request.url.payments') + '/' + payment._id, {
      ...state.get('request.params.api.delete')
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) {
          this.model.payments = this.model.payments.filter(
            (current: Payment) => current._id !== payment._id
          );
          this.snackbar.message = data.message;
          this.snackbar.color = 'success';
          this.snackbar.icon = 'las la-check-square';
          this.snackbar.visible = true;
        } else {
          this.snackbar.visible = true;
          this.snackbar.color = 'error';
          this.snackbar.icon = 'las la-bug';
          this.snackbar.message = data.message;
        }
      });
  }
}
