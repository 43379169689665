


































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { FrontendLicense, LicenseType } from "../../../../types";

export const licenseTypes: LicenseType[] = [
  { text: "Free", value: "free", version: 1.1 },
  { text: "Trial", value: "trial", version: 1.1 },
  { text: "Individual", value: "individual", version: 3 },
  { text: "Regular", value: "regular", version: 3 },
  { text: "Enterprise", value: "enterprise", version: 3 },
  { text: "Corporate", value: "corporate", version: 3 },
  { text: "Ultimate", value: "ultimate", version: 3 },
  { text: "Regular (one-year)", value: "regular-one-year", version: 1.1 },
  { text: "Ultimate (one-year)", value: "ultimate-one-year", version: 1.1 },
];

@Component({
  name: "LicenseForm",
})
export default class LicenseForm extends Vue {
  types: LicenseType[] = licenseTypes;
  @Prop({
    default: {
      company: "",
      email: "",
      type: { text: "Regular", value: "regular", version: 1.1 },
      quantity: 100,
      version: 1,
      price: 0,
      currency: "USD",
      domains: [],
      repository: "",
      validUntil: null,
      valid: false,
    },
  })
  private model!: FrontendLicense;

  rules = {
    single: [(value: string) => !!value || "This field is required."],
    number: [
      (value: number) => {
        value = Number(value);
        if (isNaN(value)) return "This field is required.";
        return true;
      },
    ],
    multi: [(value: string[]) => !!value.length || "This field is required"],
  };

  delimeters = [" ", ","];

  mounted() {
    this.$emit("form", this.$refs.form);
  }
}
